import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { NextPage } from "next";
import { SessionProvider, signOut } from "next-auth/react";
import { AppProps } from "next/app";
import Script from "next/script";
import { ReactElement, ReactNode, useEffect } from "react";
import "remixicon/fonts/remixicon.css";
import packageJson from "../package.json";
import "../styles/globals.css";
import '../styles/mantine.css';
import DataProvider from "../states/provider/data.provider";
import SoundCloudScript from "../utils/SoundCloudScript";

type NextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

function App({ Component, pageProps: { session, ...pageProps } }) {
	useEffect(() => {
		const serverVersion = packageJson.version;
		const userVersion = localStorage?.getItem("version");

		try {
			if (serverVersion !== userVersion) {
				const serverVersionParts = serverVersion.split(".");
				const userVersionParts = userVersion?.split(".");

				// Check if major or minor version has changed
				if (
					userVersionParts &&
					serverVersionParts &&
					serverVersionParts.length >= 2 &&
					userVersionParts.length >= 2 &&
					(serverVersionParts[0] !== userVersionParts[0] || serverVersionParts[1] !== userVersionParts[1])
				) {
					alert("Ada update versi baru nih. Halaman akan refresh");
					localStorage.setItem("version", serverVersion);
					localStorage.removeItem("popupClosedAt");

					// Force a page refresh for major or minor version change
					signOut();
					// window.location.reload();
				} else if (serverVersion !== userVersion) {
					// If only patch version has changed
					alert("Ada update versi baru nih. Halaman akan refresh");
					localStorage.setItem("version", serverVersion);
					localStorage.removeItem("popupClosedAt");

					// Force a page refresh for patch version change
					window.location.reload();
				}
			}
		} catch (error) {
			console.error("Error:", error);
		}
	}, []);

	return (
		<DataProvider>
			<SoundCloudScript />
			<Script
				id="fb-pixel"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', ${process.env.NEXT_PUBLIC_META_PIXEL_ID});
                `,
				}}
			/>

			<Script
				strategy="lazyOnload"
				src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_MEASUREMENT_ID}`}
			/>

			<Script id="google-analytics-script" strategy="lazyOnload">
				{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_MEASUREMENT_ID}', {
          page_path: window.location.pathname,
          });
    `}
			</Script>
			<SessionProvider session={pageProps.session}>
				<MantineProvider
					withNormalizeCSS
					withGlobalStyles
					theme={{
						// colorScheme: 'dark',
						focusRing: "never",
						components: {
							InputWrapper: {
								styles: (theme) => ({
									description: {
										fontSize: "12px",
									},
								}),
							},
						},
					}}
				>
					<Notifications position="top-center" />
					<Component {...pageProps} />
				</MantineProvider>
			</SessionProvider>
		</DataProvider>
	);
}
export default App;
